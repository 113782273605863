import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
//datepicker
import Datepickers from '../../../components/Datepicker';
import axios from '../../../../../server/node_modules/axios';





const Customeradd = () => {
    const [fullName, setFullName] = useState('');
    const [gender, setGender] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [taxInfo, setTaxInfo] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [poc, setPoc] = useState('');
    const [pocPhone, setPocPhone] = useState('');
    const [bio, setBio] = useState('');
    const [filename, setFile] = useState(null);
    const [notificationType, setNotificationType] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('fullName', fullName);
        formData.append('gender', gender);
        formData.append('birthDay', birthDay);
        formData.append('taxInfo', taxInfo);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('address', address);
        formData.append('zipcode', zipcode);
        formData.append('poc', poc);
        formData.append('pocPhone', pocPhone);
        formData.append('bio', bio);
        formData.append('notificationType', notificationType);

        // Get the file from the file input
        const file = document.getElementById('customFile').files[0];
        formData.append('filename', file);

        try {
            const response = await axios.post('http://localhost:5000/customer-add', formData);
            console.log(response.data);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customer">Customers</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Customer</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/customer" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold0 d-flex align-items-center">Add New Customer</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row>

                                    <Col md="12">
                                        <Form className="row g-3 date-icon-set-modal" onSubmit={handleSubmit}>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Full Name / Company Name</Form.Label>
                                                <Form.Control type="text" id="Text1" placeholder="Enter Full Name" value={fullName} onChange={e => setFullName(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Gender</Form.Label><br />
                                                <div className="form-check form-check-inline">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Form.Control type="radio" id="inlineRadio1" name="customRadio-1" className="custom-control-input" value="Male" onChange={e => setGender(e.target.value)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineRadio1"> Male </Form.Label>
                                                    </div>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Form.Control type="radio" id="inlineRadio2" name="customRadio-1" className="custom-control-input" value="Female" onChange={e => setGender(e.target.value)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineRadio2"> Female </Form.Label>
                                                    </div>
                                                </div>
                                                <div className="form-check form-check-inline mt-2">
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Form.Control type="radio" id="inlineRadio2" name="customRadio-1" className="custom-control-input" value="Other" onChange={e => setGender(e.target.value)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineRadio3"> Other </Form.Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3 position-relative">
                                                <Form.Label htmlFor="Text2" className="font-weight-bold text-muted text-uppercase">Birth Day</Form.Label>
                                                <Datepickers
                                                    type="text"
                                                    className="vanila-datepicker"
                                                    id="Text2"
                                                    name="event_date"
                                                    placeholder="Enter Birth Day"
                                                    autoComplete="off"
                                                    value={birthDay}
                                                    onChange={e => setBirthDay(e.target.value)}  // Check this line
                                                />
                                                <span className="search-link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text3" className="font-weight-bold text-muted text-uppercase">Tax Info</Form.Label>
                                                <Form.Control type="text" id="Text3" placeholder="Enter Tax Info" value={taxInfo} onChange={e => setTaxInfo(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control type="text" id="Text4" placeholder="Enter Email" value={email} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control type="text" id="Text5" placeholder="Enter Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputcountry" className="font-weight-bold text-muted text-uppercase">Country</Form.Label>
                                                <select defaultValue="" id="inputcountry" className="form-select form-control choicesjs" value={country} onChange={e => setCountry(e.target.value)} >
                                                    <option value="" hidden disabled>Select Country</option>
                                                    <option value="USA">USA</option>
                                                    <option value="UK">UK</option>
                                                    <option value="France">France</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">State/Region</Form.Label>
                                                <select defaultValue="" id="inputState" className="form-select form-control choicesjs" value={state} onChange={e => setState(e.target.value)}>
                                                    <option value="" hidden disabled>Select State/Region</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Briston">Briston</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="California">California</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">Address</Form.Label>
                                                <Form.Control type="text" id="Text6" placeholder="Enter Address" value={address} onChange={e => setAddress(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Zipcode</Form.Label>
                                                <Form.Control type="text" id="Text7" placeholder="Enter Zipcode" value={zipcode} onChange={e => setZipcode(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Point of Contact</Form.Label>
                                                <Form.Control type="text" id="Text7" placeholder="Enter Name" value={poc} onChange={e => setPoc(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">POC Phone</Form.Label>
                                                <Form.Control type="text" id="Text7" placeholder="Enter POC Phone" value={pocPhone} onChange={e => setPocPhone(e.target.value)} />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <Form.Label htmlFor="Text9" className="font-weight-bold text-muted text-uppercase">Bio</Form.Label>
                                                <Form.Control as="textarea" id="Text9" rows="2" placeholder="Enter Bio" value={bio} onChange={e => setBio(e.target.value)} />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <Form.Label className="form-label font-weight-bold text-muted text-uppercase mb-3">Notification Type</Form.Label><br />
                                                <div className="form-check form-check-inline">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <Form.Control type="checkbox" className="custom-control-input m-0" id="inlineCheckbox1" value={notificationType} onChange={e => setNotificationType(e.target.checked)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineCheckbox1">Email</Form.Label>
                                                    </div>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <Form.Control type="checkbox" className="custom-control-input m-0" id="inlineCheckbox2" value={notificationType} onChange={e => setNotificationType(e.target.checked)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineCheckbox2">SMS</Form.Label>
                                                    </div>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <Form.Control type="checkbox" className="custom-control-input m-0" id="inlineCheckbox3" value={notificationType} onChange={e => setNotificationType(e.target.checked)} />
                                                        <Form.Label className="custom-control-label" htmlFor="inlineCheckbox3">Phone</Form.Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <Form.Label htmlFor="Text6" className="font-weight-bold text-muted text-uppercase">File</Form.Label>
                                                <Form.File custom className="mb-3">
                                                    <Form.File.Input id="customFile" name="filename" onChange={e => setFile(e.target.files[0])} />
                                                    <Form.File.Label htmlFor="customFile">Choose file</Form.File.Label>
                                                </Form.File>
                                            </div>


                                            <div className="d-flex justify-content-end mt-3">
                                                <Button variant="btn btn-primary" onClick={handleSubmit}>
                                                    Add Customer
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >

        </>
    )
}

export default Customeradd;